.horlogeContainer {
  text-align: center !important;
  border-top: solid;
  border-bottom: solid;
  border-width: thin;
  margin: {
    bottom: 10px;
    top: 10px;
  }
}

.dateAndTimeContainer {
  //color: rgb(238,238,238);
  color: #f5f5f5;
  margin-left: 40px;
}

.ionIconHorloge {
  font: {
    size: 12px;
  }
  margin-right: 40px;
}

.horlogeAujourdhui {

}

.horlogeDateContainer {

}
.horlogeDate {
  font-size: 13px;

}
.horlogeTimeContainer {

}

.horlogeTime {
  font-size: 26px;

}