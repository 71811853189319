@import './resources/style/index.style.scss';


body {
  /*background-color: rgb(192, 192, 192);*/
  /* background-color: #262626; */
  background-color: #dcdcdc;
}

.app {
  display: flex;
  justify-content: left;
}

.app .screen {
  /*width: 1280px;*/
  width: 100%;
}
